export interface IColors {
  green: string;
  turquoise: string;
  darkBlue: string;
  lightBlue: string;
  brown: string;
  lightBlueFaded: string;
  brownFaded: string;
  greenFaded: string;
  turquoiseFaded: string;
  darkBlueFaded: string;
  black: string;
  white: string;
  grey: string;
  errorRed: string;
}

export const lightColors: IColors = {
  green: '#0C8346',
  greenFaded: '#0C834650',
  turquoise: '#18F2B2',
  turquoiseFaded: '#18F2B250',
  darkBlue: '#083D77',
  darkBlueFaded: '#083D7710',
  lightBlue: '#0094C6',
  lightBlueFaded: '#0094C615',
  brown: '#DDA448',
  brownFaded: '#DDA44850',
  black: 'black',
  white: 'white',
  grey: '#3a3b3a',
  errorRed: '#d32f2f'
};
