import { Divider } from '@mui/material';
import { lightColors } from '../../../constants/colors';

export const LinkDivider = () => {
  return (
    <Divider
      orientation='vertical'
      flexItem
      sx={{ color: lightColors.grey, height: 40, mt: 'auto', mb: 'auto' }}
    />
  );
};
