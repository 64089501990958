export enum RoutePath {
  HOME = '/',
  ABOUT = '/about',
  SERVICES = '/services',
  FEES = '/fees',
  CONTACT = '/contact',
  // REFUGEE = '/refugee',
  FAQ = '/faq',
  SUPERVISION_AND_TRAINING = '/s-and-t',
  UNKNOWN = '*',
}
