import * as Yup from 'yup';

import { yupNameValidation } from '../../yup/name';
import { yupEmailValidation } from '../../yup/email';
import { yupMessageValidation } from '../../yup/message';
import { ContactFormInputs } from './types';

export const ContactFormSchema = Yup.object({
  name: yupNameValidation,
  email: yupEmailValidation,
  message: yupMessageValidation,
});
