import { Box, Typography } from '@mui/material';
import React from 'react';
import { mobileNavStyles } from '../../styles/mobile-nav-styles';

export const HeaderText: React.FC = () => {
  return (
    <Box>
      <Typography sx={mobileNavStyles}>Dr Charlotte Flothmann</Typography>
      <Typography
        sx={{ ...mobileNavStyles, fontSize: { xs: 10, md: 15 } }}
      >
        Chartered Counselling Psychologist, DPsych, CPsychol, BPS & HCPC reg.
      </Typography>
      <Typography
        sx={{
          ...mobileNavStyles,
          fontSize: { xs: 10, md: 15 },
          pb: { xs: 3, md: 0 },
        }}
      >
        Psychological therapy in English, German, French and Spanish
      </Typography>
    </Box>
  );
};
