import { lightColors } from '../../constants/colors';

export const mobileNavStyles = {
  fontSize: { xs: 25, md: 35 },
  color: lightColors.grey,
  fontWeight: 600,
  pt: { xs: 1, md: 3 },
  width: 'fit-content',
  // width: { xs: 'fit-content', md: 'auto' },
  ml: 'auto',
  mr: 'auto',
};
