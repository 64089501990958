import React from 'react';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { TopNav } from '../navigation/top-nav';
import { TopNavMobile } from '../navigation/top-nav-mobile';

export const Header: React.FC = () => {
  const isMobile = useIsMobile();

  return isMobile ? <TopNavMobile /> : <TopNav />;
};
