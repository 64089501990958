import { Box, Divider, Fade, Link, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { lightColors } from '../../../constants/colors';
import { footerStyles } from '../../styles/footer-styles';
import { RoutePath } from '../../../constants/routes';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { LinkDivider } from '../../components/link-divider';
type Props = {};

export const Footer = (props: Props) => {
  const isMobile = useIsMobile();
  const [isClicked, setIsClicked] = useState<boolean>(false);

  const handleIsclicked = () => setIsClicked(true);
  return isMobile ? (
    <Stack sx={{ backgroundColor: lightColors.greenFaded }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly', p: 3 }}>
        <Link
          href={RoutePath.FAQ}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <Typography>FAQs</Typography>
        </Link>
        <Divider
          orientation='vertical'
          flexItem
          sx={{ color: lightColors.grey }}
        />
        <Link
          href={RoutePath.CONTACT}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <Typography>Contact</Typography>
        </Link>
        <Divider
          orientation='vertical'
          flexItem
          sx={{ color: lightColors.grey }}
        />
        {!isClicked ? (
          <Typography onClick={handleIsclicked} sx={{ cursor: 'pointer' }}>
            Credits
          </Typography>
        ) : (
          <Link
            href='https://joshknight.site'
            target='_blank'
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: 'inherit',
            }}
          >
            <Typography>joshknight.site</Typography>
          </Link>
        )}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        {/* <i
          className='fa-solid fa-copyright'
          style={{
            color: lightColors.grey,
            marginLeft: '5px',
            marginRight: '5px',
            paddingTop: '5px',
          }}
        ></i> */}
        <CopyrightIcon
          sx={{
            color: lightColors.grey,
            mr: 0.5,
          }}
        />
        <Typography>Copyright 2023 Charlotte Flothmann</Typography>
      </Box>
    </Stack>
  ) : (
    <Stack
      direction='row'
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        backgroundColor: lightColors.greenFaded,
      }}
    >
      <Box sx={footerStyles}>
        <i
          className='fa-solid fa-copyright'
          style={{
            color: lightColors.grey,
            marginLeft: '5px',
            marginRight: '5px',
          }}
        ></i>

        <Typography>Copyright 2023 Charlotte Flothmann</Typography>
      </Box>
      <LinkDivider />
      <Box sx={footerStyles}>
        <Link
          href={RoutePath.FAQ}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <Typography>FAQs</Typography>
        </Link>
      </Box>
      <LinkDivider />

      <Box sx={footerStyles}>
        <Link
          href={RoutePath.CONTACT}
          sx={{
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <Typography>Contact</Typography>
        </Link>
      </Box>
      <LinkDivider />

      <Box sx={footerStyles}>
        <Typography onClick={handleIsclicked} sx={{ cursor: 'pointer' }}>
          Credit
        </Typography>

        <Fade in={isClicked} timeout={3000}>
          <Link
            href='https://joshknight.site'
            target='_blank'
            sx={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: 'inherit',
            }}
          >
            <Typography>joshknight.site</Typography>
          </Link>
        </Fade>
      </Box>
    </Stack>
  );
};
