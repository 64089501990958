export const subtitleStyles = {
  fontWeight: 'bold',
  mb: 1,
  mt: 5,
  fontSize: { md: 25 },
};

export const topSubtitleStyles = {
  fontWeight: 'bold',
  mb: 1,
  mt: 8,

  fontSize: { md: 25 },
};
