import {
  Card,
  CardContent,
  CardMedia,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import brookPic from '../../assets/images/brook.jpg';
import { PageBanner } from '../../component-library/components/page-banner';
import {
  textCardStyles,
  textCardStylesTop,
} from '../../component-library/styles/text-card-styles';
import { listOfConditions } from './list-of-conditions';
import { RoutePath } from '../../constants/routes';
import { textStyles } from '../../component-library/styles/text-styles';
import { bgStyles } from '../../component-library/styles/bg-styles';
import { PageTitle } from '../../component-library/page-layout/page-title';
import { TextAccordion } from '../../component-library/components/text-accordion';

import brookAndTreesPic from '../../assets/images/brook-with-trees.jpg';

export const HomePage: React.FC = () => {
  const displayConditions = listOfConditions.map((con, idx) => {
    return (
      <ListItem key={idx}>
        <Typography>{con}</Typography>
      </ListItem>
    );
  });

  return (
    <Stack sx={bgStyles}>
      <PageBanner image={brookPic} alt={'a brook and mountains.'} />
      <Card sx={textCardStylesTop}>
        <CardContent>
          <PageTitle
            variant='h1'
            title={
              'I provide psychological therapy and specialise in supporting people facing emotional challenges'
            }
            imageURL={brookAndTreesPic}
          />
          <Typography sx={{ mb: 1 }}>
            These might relate to specific life circumstances you are facing, a
            diagnosis you have received, or a persistent issue you find
            difficult to understand or shift.
          </Typography>
          <Typography>
            Perhaps you are experiencing difficulties in your relationships with
            others, or how you relate to yourself. Maybe you find it hard to
            deal with work stress, constant worrying or fear and panic. You may
            be struggling to sleep or look after yourself, and see your sense of
            wellbeing and purpose impacted. You want to get to a place of
            greater clarity and resilience.
          </Typography>
          {/* <TextAccordion
            section={[
              {
                paragraph:
                  'These might relate to specific life circumstances you are facing, a diagnosis you have received, or a persistent issue you find difficult to understand or shift.',
              },
              {
                paragraph:
                  'Perhaps you are experiencing difficulties in your relationships with others, or how you relate to yourself. Maybe you find it hard to deal with work stress, constant worrying or fear and panic. You may be struggling to sleep or look after yourself, and see your sense of wellbeing and purpose impacted. You want to get to a place of greater clarity and resilience.',
              },
            ]}
          /> */}

          {/* <Typography sx={textStyles}>
            I provide psychological therapy and specialise in supporting people
            facing emotional challenges.
          </Typography>
          <Typography sx={textStyles}>
            These might relate to specific life circumstances you are facing, a
            diagnosis you have received, or a persistent issue you find
            difficult to understand or shift. Perhaps you are experiencing
            difficulties in your relationships with others, or how you relate to
            yourself. Maybe you find it hard to deal with work stress, constant
            worrying or fear and panic. You may be struggling to sleep or look
            after yourself, and see your sense of wellbeing and purpose
            impacted. You want to get to a place of greater clarity and
            resilience.
          </Typography> */}
          {/* <Typography sx={textStyles}>
            I will listen to you without judgment and try to understand your
            experience from your perspective. I will share my observations and
            insights with you, and if appropriate, teach you skills to help you
            cope better. At all times I will work with you to find what is most
            effective for you, acknowledging that this will be different for
            everyone.
          </Typography> */}
        </CardContent>
      </Card>

      <Card sx={textCardStyles}>
        <CardContent>
          <Typography sx={{ ...textStyles, fontWeight: 'bold' }}>
            Some of the problems I have supported clients with include:
          </Typography>
          <List sx={textStyles}>{displayConditions}</List>
        </CardContent>
      </Card>

      <Card sx={textCardStyles}>
        <CardContent>
          {/* <PageTitle variant='h2' /> */}
          <TextAccordion
            title={'I work with adults, young people, couples and families'}
            section={[
              {
                paragraph:
                  'I will listen to you without judgment and try to understand your experience from your perspective. I will share my observations and insights with you, and if appropriate, teach you skills to help you cope better. At all times I will work with you to find what is most effective for you, acknowledging that this will be different for everyone.',
              },
              {
                paragraph:
                  'Having worked with people of all ages and backgrounds, with different experiences and concerns, I have seen time and time again the potential for people to feel better even when they believed, or were told, that they would not. Using evidence-based interventions in a collaborative manner, I can offer psychological support with many different issues.',
              },
            ]}
          />
        </CardContent>
      </Card>

      <Card sx={textCardStyles}>
        <CardContent>
          {/* <Typography sx={textStyles}>
            Having worked with people of all ages and backgrounds, with
            different experiences and concerns, I have seen time and time again
            the potential for people to feel better even when they believed, or
            were told, that they would not. Using evidence-based interventions
            in a collaborative manner, I can offer psychological support with
            many different issues.
          </Typography> */}
          {/* <Typography sx={textStyles}>
            I work with adults and young people, couples and families.
          </Typography> */}
          <Typography sx={textStyles}>
            I practise in English, German, Spanish and French; online and face
            to face depending on your preference. My practice is currently based
            in Bristol (UK) and I also offer remote therapy and consultancy
            nationally and internationally.
          </Typography>
          <Typography sx={textStyles}>
            I welcome referrals from individuals as well as third-parties.
            Please&nbsp;
            <Link href={RoutePath.CONTACT} color='inherit'>
              contact me&nbsp;
            </Link>
            to find out more.
          </Typography>
          <Typography sx={textStyles}>
            Every person is different and each situation is unique. If you would
            like to find out whether I might be able to support you with what
            you are facing, please&nbsp;
            <Link href={RoutePath.CONTACT} color='inherit'>
              contact me&nbsp;
            </Link>
            to discuss. If I do not feel able to offer help, I will suggest
            possible alternatives to you.
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
