import {
  Avatar,
  Box,
  Collapse,
  Link,
  List,
  ListItem,
  ListItemText,
  Stack,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Links } from '../constants';
import { RouterLinkMobile } from '../../../components/router-links';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import avatarPortrait from '../../../../assets/images/portrait-for-avatar.jpg';
import { lightColors } from '../../../../constants/colors';
import { iconButtonStyles } from '../../../styles/icon-button-styles';
import { HeaderText } from '../../../components/header-text';
import { RoutePath } from '../../../../constants/routes';

export const TopNavMobile: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef<HTMLElement>(null);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (
        isOpen &&
        ref.current &&
        !ref.current.contains(event.target as Element)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [isOpen]);

  return (
    <Stack
      sx={{
        backgroundColor: lightColors.lightBlueFaded,
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link
          href={RoutePath.HOME}
          sx={{
            backgroundColor: 'inherit',
            '&:active': {
              backgroundColor: 'none',
            },
            cursor: 'pointer',
          }}
        >
          <Avatar
            src={avatarPortrait}
            sx={{ width: 50, height: 50, ml: 1, mt: 1 }}
          />
        </Link>
        <IconButton
          onClick={!isOpen ? handleIsOpen : handleClose}
          sx={{ ...iconButtonStyles, pt: 0, pb: 0 }}
        >
          {!isOpen ? (
            <MenuIcon sx={{ color: lightColors.grey }} />
          ) : (
            <CloseIcon sx={{ color: lightColors.grey }} />
          )}
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
          mr: 'auto',
          ml: 'auto',
          textAlign: 'center',
        }}
        ref={ref}
      >
        <HeaderText />

        <Collapse in={isOpen} timeout={500}>
          <List>
            {Links.map(({ href, label }) => {
              return (
                <RouterLinkMobile key={href} href={href}>
                  <ListItem key={label} onClick={handleClose} disablePadding>
                    <ListItemText
                      primary={label}
                      sx={{ color: lightColors.grey, ml: 2 }}
                    />
                  </ListItem>
                </RouterLinkMobile>
              );
            })}
          </List>
        </Collapse>
      </Box>
    </Stack>
  );
};
