export const textCardStylesTop = () => ({
  width: '90%',
  maxWidth: '90%',
  mt: -10,
  ml: 'auto',
  mr: 'auto',
  mb: 7,
  pt: 2,
  pl: 2,
  pr: 2,
  textAlign: 'center',
});

export const textCardStyles = () => ({
  width: '90%',
  maxWidth: '90%',
  ml: 'auto',
  mr: 'auto',
  mb: 7,
  pt: 2,
  pl: 2,
  pr: 2,
  textAlign: 'center',
});
