import { Box, Divider, Typography } from '@mui/material';
import { lightColors } from '../../../constants/colors';
import { textStyles } from '../../styles/text-styles';

interface IPageTitleProps {
  title: string;
  variant: any;
  imageURL: string;
}

export const PageTitle: React.FC<IPageTitleProps> = ({
  title,
  variant,
  imageURL,
}) => {
  return (
    <>
      <Typography
        variant={variant}
        sx={{
          fontSize: 40,
          p: 3,
          width: { xs: '100%', md: '70%' },
          mr: { md: 'auto' },
          ml: { md: 'auto' },
          mb: 5,
          textAlign: 'center',
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          height: 150,
          width: 'auto',
          // borderRadius: 100,
          backgroundImage: `url(${imageURL})`,
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          mb: 10,
        }}
      />
      <Divider sx={textStyles} />
    </>
  );
};
