import { Card, CardContent, CardMedia, Stack, Typography } from '@mui/material';
import React from 'react';
import portrait from '../../assets/images/portrait.jpg';
import { PageBanner } from '../../component-library/components/page-banner';
import { textCardStylesTop } from '../../component-library/styles/text-card-styles';
import mountainTop from '../../assets/images/on-mountaintop.jpg';
import { fonts } from '../../constants/fonts';
import { bgStyles } from '../../component-library/styles/bg-styles';
import { PageTitle } from '../../component-library/page-layout/page-title';

import treesAndrock from '../../assets/images/trees-and-cylindrical-stones.jpg';


export const AboutPage: React.FC = () => {
  return (
    <Stack sx={bgStyles}>
      <PageBanner image={mountainTop} alt={'mountain tops'} />
      <Card sx={textCardStylesTop}>
        <CardContent>
          <PageTitle variant='h1' title={'About Me'} imageURL={treesAndrock} />
          <Typography sx={{ mb: 2 }}>
            As a chartered counselling psychologist, I specialise in supporting
            people of all ages toward better mental health. I did my doctoral
            training at City, University of London, and also hold degrees from
            the Universities of Oxford and Bristol. I am BPS accredited and HCPC
            registered (PYL37674) and abide by their ethical guidelines.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            I currently teach psychology at doctoral level and have trained
            corporations, institutions and government bodies on applying
            psychological theory within their workplaces. This requires me to
            continuously engage with latest psychology research which in turn
            informs my clinical practice.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            I have expertise working with both symptom reduction as well as
            helping clients to understand why they might be feeling as they are.
            I recognise the importance for clients to be able to function day to
            day and can support them to make changes towards this goal. Many of
            my clients have lived with difficulties for years and find it
            helpful to have a safe, confidential space in which to talk with a
            professional, often for the first time.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Gaining greater understanding of why we feel and act how we do can
            give more choice as to how we wish to live our lives. To this end,
            my practice is informed by a wide range of evidence-based approaches
            which I draw on flexibly to meet each client’s need and preference.
            I consult regularly with an expert clinical supervisor and seek
            specialist training to ensure I am offering best practice.
          </Typography>
        </CardContent>
        <Card
          elevation={0}
          sx={{ maxWidth: '90%', ml: 'auto', mr: 'auto', textAlign: 'center' }}
        >
          <CardMedia
            component='img'
            height='450'
            image={portrait}
            alt='Portrait of Dr Charlotte Flothmann'
            sx={{ maxHeight: 450, width: 'auto', ml: 'auto', mr: 'auto' }}
          />

          <CardContent>
            <Typography>Dr Charlotte Flothmann </Typography>
            <Typography
              sx={{
                display: 'inline-block',
                fontFamily: fonts.brandFont,
                fontSize: 10,
                fontWeight: 400,
              }}
            >
              DPsych CPsychol
            </Typography>
          </CardContent>
        </Card>
      </Card>
    </Stack>
  );
};
