import React, { useEffect, useState } from 'react';
import { FormButton } from '../form-button';
import {
  Card,
  CardContent,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  textCardStyles,
  textCardStylesTop,
} from '../../styles/text-card-styles';
import { textStyles } from '../../styles/text-styles';
import { PageTitle } from '../../page-layout/page-title';
import emailjs from '@emailjs/browser';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { ContactFormInputs } from '../../../validation/schemas/contact-form/types';
import { ContactFormSchema } from '../../../validation/schemas/contact-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { errorTextStyles } from '../../styles/error-text-styles';
import { FormSubmitDialogue } from '../form-submit-dialogue';
import mountainAndRoad from '../../../assets/images/desert-mountain-road-foreground.jpg';

export const ContactForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    reset,
    watch,
    control,
    formState,
    formState: { errors, isSubmitSuccessful },
  } = useForm<ContactFormInputs>({
    mode: 'onChange',
    resolver: yupResolver(ContactFormSchema),
  });

  const handleClose = () => setIsOpen(false);

  const onSubmit: SubmitHandler<ContactFormInputs> = (data) => {
    setIsLoading(true);

    emailjs
      .sendForm(
        'service_C_Flothmann',
        'template_xonl4ui',
        '#contact-form',
        'M_EYcXlp5FYSimPlC'
      )
      .then(
        (result) => {
          console.log(result);
          setIsOpen(true);
        },
        (error) => {
          return error;
        }
      );
  };

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset({ name: '', email: '', message: '' });
      setIsLoading(false);
    }
  }, [formState, reset]);

  return (
    <Stack
      sx={{
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        m: 3,
      }}
    >
      <Card sx={textCardStylesTop}>
        <CardContent>
          <PageTitle
            variant='h1'
            title={'Contact Me'}
            imageURL={mountainAndRoad}
          />

          <Typography sx={textStyles}>
            Send me a message to book an initial appointment. I will get back to
            you as soon as I am able, usually within 3 working days.
          </Typography>
          <Typography sx={textStyles}>
            I look forward to hearing from you.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} id='contact-form'>
            <Typography sx={errorTextStyles}>{errors.name?.message}</Typography>

            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  placeholder='Name...'
                  error={Boolean(errors.name?.message)}
                  sx={{ mt: 3, mb: 3, width: '100%' }}
                />
              )}
            />
            <Typography sx={errorTextStyles}>
              {errors.email?.message}
            </Typography>

            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  placeholder='Email...'
                  error={Boolean(errors.email?.message)}
                  sx={{ mt: 3, mb: 3, width: '100%' }}
                />
              )}
            />
            <Typography sx={errorTextStyles}>
              {errors.message?.message}
            </Typography>

            <Controller
              name='message'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  multiline
                  minRows={6}
                  margin='dense'
                  placeholder='Message...'
                  error={Boolean(errors.message?.message)}
                  fullWidth
                  sx={{
                    mt: 3,
                    mb: 3,
                    width: '100%',
                    overflow: 'scroll',
                    borderStyle: 'solid',
                  }}
                />
              )}
            />

            <FormButton loading={isLoading} />
          </form>
        </CardContent>
      </Card>
      {isOpen && <FormSubmitDialogue handleClose={handleClose} />}
    </Stack>
  );
};
