import React from 'react';

import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import wideshot from '../../assets/images/wideshot-mountain.jpg';
import { PageBanner } from '../../component-library/components/page-banner';
import { PageTitle } from '../../component-library/page-layout/page-title';
import { bgStyles } from '../../component-library/styles/bg-styles';
import {
  textCardStyles,
  textCardStylesTop,
} from '../../component-library/styles/text-card-styles';
import { RouterLink } from '../../component-library/components/router-links';
import { Links } from '../../component-library/page-layout/navigation/constants';
import { lightColors } from '../../constants/colors';
import brookAndTreesPic from '../../assets/images/brook-with-trees.jpg';

export const NotFoundPage: React.FC = () => {
  return (
    <Stack sx={bgStyles}>
      <PageBanner image={wideshot} alt={'wideshot of mountains'} />

      <Card sx={{ ...textCardStyles, mt: -2 }}>
        <CardContent>
          <PageTitle
            variant='h1'
            title={'Page not found'}
            imageURL={brookAndTreesPic}
          />
          <Typography>
            Sorry, we couldn't find the page you're looking for.
          </Typography>
          <Typography>
            Hopefully one of the links below will take you where you need to go.
            Sorry, we couldn't find the page you're looking for.
          </Typography>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {Links.map(({ href, label }) => {
              return (
                <RouterLink
                  key={href}
                  href={href}
                  sx={{ width: 'fit-content' }}
                >
                  <ListItem
                    key={label}
                    disablePadding
                    sx={{ width: 'fit-content' }}
                  >
                    <ListItemText
                      primary={label}
                      sx={{ color: lightColors.green, pt: 1, mt: 0, pr: 3 }}
                    />
                  </ListItem>
                </RouterLink>
              );
            })}
          </List>
        </CardContent>
      </Card>
    </Stack>
  );
};
