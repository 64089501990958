import * as React from 'react';

import { Link } from 'react-router-dom';

import { styled } from '@mui/system';
import { lightColors } from '../../../constants/colors';

const LinkComponent = styled('div')({
  a: {
    textDecoration: 'none',
  },
  span: {
    '(min-width:1400px)': {
      fontSize: 50,
    },
    position: 'relative',
    fontSize: 20,
    '&:after': {
      content: "''",
      position: 'absolute',
      width: '100%',
      transform: 'scaleX(0)',
      height: 2,
      bottom: 0,
      left: 0,
      backgroundColor: lightColors.grey,
      transformOrigin: 'bottom right',
      transition: 'transform 0.25s ease-out',
    },
    '&:hover:after': {
      transform: 'scaleX(1)',
      transformOrigin: 'bottom left',
    },
  },
});

const MobileLinkComponent = styled('div')({
  a: {
    textDecoration: 'none',
  },
});

export const RouterLink: React.FC<
  React.PropsWithChildren<{ href: string; sx?: any }>
> = ({ href, children, sx }) => {
  return (
    <LinkComponent sx={sx}>
      <Link to={href}>{children}</Link>
    </LinkComponent>
  );
};

export const RouterLinkMobile: React.FC<
  React.PropsWithChildren<{ href: string; sx?: any }>
> = ({ href, children, sx }) => {
  return (
    <MobileLinkComponent sx={sx}>
      <Link to={href}>{children}</Link>
    </MobileLinkComponent>
  );
};
