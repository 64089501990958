import { Box, Collapse, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { iconButtonStyles } from '../../styles/icon-button-styles';
import { lightColors } from '../../../constants/colors';

interface ITextAccordionProps {
  title?: string;
  subtitle?: string;
  section: { paragraph: string; style?: {} }[];
}

export const TextAccordion: React.FC<ITextAccordionProps> = ({
  title,
  subtitle,
  section,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleClickAway = () => setIsOpen(false);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Typography
          sx={{
            fontSize: 30,
            p: 3,
            width: { xs: '100%', md: '70%' },
            mr: { md: 'auto' },
            ml: { md: 'auto' },
            color: lightColors.grey,
            textAlign: 'center',
          }}
          variant={'h2'}
        >
          {title}
        </Typography>
        <Typography sx={{ mt: 2, mb: 2 }}>{subtitle}</Typography>
        {!isOpen && (
          <IconButton onClick={handleIsOpen} sx={iconButtonStyles}>
            <Typography>Find out more</Typography>
            <ArrowDownwardIcon />
          </IconButton>
        )}
        <Collapse in={isOpen} timeout={500}>
          {section.map(({ paragraph, style }) => {
            return (
              <Typography sx={{ ...style, mt: 2 }}>{paragraph}</Typography>
            );
          })}
          {isOpen && (
            <IconButton onClick={handleIsOpen} sx={iconButtonStyles}>
              <Typography>Less</Typography>
              <ArrowUpwardIcon />
            </IconButton>
          )}
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
};
