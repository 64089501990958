import { Stack } from '@mui/material';
import React from 'react';
import { ContactForm } from '../../component-library/components/contact-form';
import dusk from '../../assets/images/dusk-mountain-cropped.jpg';
import { PageBanner } from '../../component-library/components/page-banner';
import { bgStyles } from '../../component-library/styles/bg-styles';

export const ContactPage: React.FC = () => {
  return (
    <Stack sx={bgStyles}>
      <PageBanner image={dusk} alt={'mountains at dusk'} />
      <ContactForm />
    </Stack>
  );
};
