import {
  List,
  ListItem,
  ListItemText,
  Box,
  Stack,
  Avatar,
  Link,
} from '@mui/material';
import { lightColors } from '../../../../constants/colors';
import { Links } from '../constants';
import { RouterLink } from '../../../components/router-links';
import { LinkDivider } from '../../../components/link-divider';
import avatarPortrait from '../../../../assets/images/portrait-for-avatar.jpg';
import { HeaderText } from '../../../components/header-text';
import { RoutePath } from '../../../../constants/routes';

export const TopNav: React.FC = () => {
  return (
    <Stack sx={{ backgroundColor: lightColors.lightBlueFaded }}>
      <Box display='flex' justifyContent='center'>
        <Link
          href={RoutePath.HOME}
          sx={{
            backgroundColor: 'inherit',
            '&:active': {
              backgroundColor: 'none',
            },
            cursor: 'pointer',
          }}
        >
          <Avatar
            alt='Portrait of dr Charlotte Flothmann'
            src={avatarPortrait}
            style={{
              width: 100,
              height: 100,
              position: 'absolute',
              left: 20,
              top: 20,
            }}
          />
        </Link>
        <HeaderText />
      </Box>
      <Box display='flex' justifyContent='center' sx={{ pt: 4 }}>
        <List component={Stack} direction='row' display='flex' flexWrap='wrap'>
          {Links.map(({ href, label }, idx) => {
            const lastElement = Links.length - 1;
            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pb: 3,
                  pt: 3,
                }}
              >
                <RouterLink key={href} href={href}>
                  <ListItem key={label} disablePadding>
                    <ListItemText
                      primary={label}
                      sx={{
                        color: lightColors.grey,
                        mt: 0,
                        mr: 2,
                        ml: 2,
                      }}
                    />
                  </ListItem>
                </RouterLink>
                {idx !== lastElement && <LinkDivider />}
              </Box>
            );
          })}
        </List>
      </Box>
    </Stack>
  );
};
