import { Card, CardContent, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { PageBanner } from '../../component-library/components/page-banner';
import desert from '../../assets/images/desert-mountain.jpg';
import { textCardStylesTop } from '../../component-library/styles/text-card-styles';
import {
  subtitleStyles,
  topSubtitleStyles,
} from '../../component-library/styles/subtitle-styles';
import { bgStyles } from '../../component-library/styles/bg-styles';
import { PageTitle } from '../../component-library/page-layout/page-title';
import brookAndTreesPic from '../../assets/images/brook-with-trees.jpg';

export const FaqPage: React.FC = () => {
  return (
    <Stack sx={bgStyles}>
      <PageBanner image={desert} alt={'Mountains in the desert'} />
      <Card sx={textCardStylesTop}>
        <CardContent>
          <PageTitle variant='h1' title={'FAQs'} imageURL={brookAndTreesPic} />
          <Typography sx={topSubtitleStyles}>
            What are the fees for therapy?
          </Typography>
          <Typography>
            I charge £100 per 50 minute session for individuals.
          </Typography>
          {/* <Typography>
            *If you are a refugee, asylum seeker or someone working in the
            refugee sector, please see{' '}
            <Link href={RoutePath.REFUGEE} color='inherit'>
              here
            </Link>
            .
          </Typography> */}
          <Typography sx={subtitleStyles}>Where do you work?</Typography>
          <Typography>
            Online, and in-person throughout Bristol (UK).
          </Typography>
          <Typography sx={subtitleStyles}>
            What if I can’t make a session?
          </Typography>
          <Typography>
            Please let me know at least 48 hours prior to your session so that
            this space can be offered to someone else. After this time sessions
            will be charged at the full rate.
          </Typography>
          <Typography sx={subtitleStyles}>Can you diagnose me?</Typography>
          <Typography>
            I do not currently diagnose clients. However, I will work with you
            to understand your experience and communicate what is going on for
            you, and I can help you communicate this to health professionals
            where appropriate.
          </Typography>
          <Typography sx={subtitleStyles}>
            What is the duration of therapy?
          </Typography>
          <Typography>
            The duration of therapy differs from person to person, and is always
            open to review. I usually see clients for one 50 minute session each
            week, either short term (around 6 sessions), or longer term.
          </Typography>
          <Typography sx={subtitleStyles}>
            Is therapy with you confidential?
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Yes. Unless concerned for your safety or another’s, or instructed by
            court, I will not share any of your details.
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Everything you share with me will be kept strictly confidential. I
            will take notes after our session which will be stored according to
            GDPR guidance and BPS ethics to protect your confidentiality.
          </Typography>
          <Typography>
            In the UK, psychologists are required to seek regular supervision to
            meet professional standards and this strives to ensure ethical
            practice. I may discuss our work with my supervisor who is also
            bound by confidentiality and ethics.
          </Typography>
          <Typography sx={subtitleStyles}>
            Can I bring a friend with me?
          </Typography>
          <Typography>
            Not normally. When working with adults, I typically see people
            individually unless I am working with couples or families
            specifically. This helps build a trusting relationship between us,
            and allows people to freely express themselves. I am more than happy
            to discuss possible concerns about attending therapy on the phone
            prior to the session, and you can also put them in an email to me.
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
