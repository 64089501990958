import { Card, CardContent, Stack } from '@mui/material';
import React from 'react';
import { PageBanner } from '../../component-library/components/page-banner';
import gravelly from '../../assets/images/gravelly-mountain.jpg';
import {
  textCardStyles,
  textCardStylesTop,
} from '../../component-library/styles/text-card-styles';

import { bgStyles } from '../../component-library/styles/bg-styles';
import { PageTitle } from '../../component-library/page-layout/page-title';
import { TextAccordion } from '../../component-library/components/text-accordion';
import {
  subtitleStyles,
  topSubtitleStyles,
} from '../../component-library/styles/subtitle-styles';
import brookAndTreesPic from '../../assets/images/brook-with-trees.jpg';

export const SupervisionAndTrainingPage: React.FC = () => {
  return (
    <Stack sx={bgStyles}>
      <PageBanner image={gravelly} alt={'Gravelly mountains'} />
      <Card sx={textCardStylesTop}>
        <CardContent>
          <PageTitle
            variant='h1'
            title={'Supervision and Training'}
            imageURL={brookAndTreesPic}
          />
          <TextAccordion
            title='Supervison'
            subtitle={'I offer both clinical and academic supervision.'}
            section={[
              {
                paragraph: 'Clinical Supervision',
                style: topSubtitleStyles,
              },
              {
                paragraph:
                  'I offer clinical supervision to therapists of all kind, and  reflective practice supervision for organisations. Please contact me if you are interested and to discuss further.',
              },
              {
                paragraph: 'Academic Supervision',
                style: topSubtitleStyles,
              },
              {
                paragraph:
                  'Over the last decade I have worked within the NHS, NGOs, as well as the education and private sectors. Initially as an educator and later as a clinician, I have worked for many years supporting people with trauma histories and related presentations. I have a particular interest in issues related to migration, diversity and inclusion, and practise in a culturally-informed manner, recognising the impact of culture and context on an individual and their experience in life. ',
              },
            ]}
          />

          {/* <Typography variant={'h4'} sx={{ mb: 5, mt: 5 }}>
            Supervision
          </Typography>
          <Typography sx={topSubtitleStyles}>Clinical Supervision:</Typography>
          <Typography sx={{ mb: 1 }}>
            I offer clinical supervision to therapists of all kind, and
            reflective practice supervision for organisations. Please contact me
            if you are interested and to discuss further.
          </Typography>
          <Typography sx={subtitleStyles}>Academic Supervision:</Typography>
          <Typography sx={{ mb: 2 }}>
            Over the last decade I have worked within the NHS, NGOs, as well as
            the education and private sectors. Initially as an educator and
            later as a clinician, I have worked for many years supporting people
            with trauma histories and related presentations. I have a particular
            interest in issues related to migration, diversity and inclusion,
            and practise in a culturally-informed manner, recognising the impact
            of culture and context on an individual and their experience in
            life.
          </Typography>
          <Typography>
            If you are a researcher looking for consultation or supervision in
            any of these topic areas, please be in touch to discuss further.
          </Typography>
        </CardContent>
      </Card>
      <Card sx={textCardStyles}>
        <CardContent>
          <Typography variant={'h4'} sx={{ mb: 5, mt: 5 }}>
            Training
          </Typography>
          <Typography>
            I have worked as a teacher and trainer for many years in both public
            and private sectors and can tailor engaging, inclusive and
            accessible training to suit for individuals and teams. My area of
            expertise is promoting mental health and wellbeing, building
            resilience and working in trauma- informed and culturally-sensitive
            ways. I have worked extensively in education settings, supporting
            inclusive practice as well as hosting refugee groups. If this is
            something of interest to you, please contact me to discuss further.
          </Typography>
        </CardContent>
      </Card> */}
        </CardContent>
      </Card>
      <Card sx={textCardStyles}>
        <CardContent>
          <TextAccordion
            title='Training'
            subtitle={
              '  I tailor engaging, inclusive and accessible training to suit for individuals and teams  .'
            }
            section={[
              {
                paragraph:
                  ' I have worked as a teacher and trainer for many years in both public and private sectors . My area of expertise is promoting mental health and wellbeing, building resilience and working in trauma- informed and culturally-sensitive ways. I have worked extensively in education settings, supporting inclusive practice as well as hosting refugee groups. If this is something of interest to you, please contact me to discuss further.',
              },
            ]}
          />
        </CardContent>
      </Card>
    </Stack>
  );
};
