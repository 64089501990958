import { RoutePath } from '../../../constants/routes';

export const Links = [
  { href: RoutePath.HOME, label: 'Home' },
  { href: RoutePath.ABOUT, label: 'About' },
  // { href: RoutePath.SERVICES, label: 'Services' },
  { href: RoutePath.FEES, label: 'Fees' },
  // { href: RoutePath.REFUGEE, label: 'Refugee' },
  {
    href: RoutePath.SUPERVISION_AND_TRAINING,
    label: 'Supervision and Training',
  },
  { href: RoutePath.CONTACT, label: 'Contact' },
  { href: RoutePath.FAQ, label: 'FAQs' },
];
