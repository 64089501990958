import { Card, CardContent, Stack, Typography } from '@mui/material';
import React from 'react';
import { PageBanner } from '../../component-library/components/page-banner';
import jungle from '../../assets/images/jungle-mouintain.jpeg';
import { textCardStylesTop } from '../../component-library/styles/text-card-styles';
import {
  subtitleStyles,
  topSubtitleStyles,
} from '../../component-library/styles/subtitle-styles';
import { bgStyles } from '../../component-library/styles/bg-styles';
import { PageTitle } from '../../component-library/page-layout/page-title';
import mountainReflection from '../../assets/images/mountains-reflected-in-water.jpg';

export const FeesPage: React.FC = () => {
  return (
    <Stack sx={bgStyles}>
      <PageBanner image={jungle} alt={'Jungle with mountains'} />
      <Card sx={textCardStylesTop}>
        <CardContent>
          <PageTitle
            variant='h1'
            title={'Fees'}
            imageURL={mountainReflection}
          />
          <Typography sx={topSubtitleStyles}>Fees</Typography>
          <Typography>For therapy</Typography>
          <Typography>
            I charge £100* per 50 minute session for individuals.
          </Typography>
          <Typography sx={subtitleStyles}>Cancellation Policy</Typography>
          <Typography>
            Please provide a minimum of 48 hours notice if you need to cancel
            your appointment. After this time sessions will be charged at the
            full rate.
          </Typography>
          <Typography sx={{ mb: 2, mt: 10 }}>
            *Depending on availability, I offer significantly reduced rates for
            refugees and asylum seekers, and for those working in the refugee
            sector. Please contact me to discuss therapy, supervision or
            training.
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
};
