import React from 'react';
import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import { lightColors } from '../../../constants/colors';
import { Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { iconButtonStyles } from '../../styles/icon-button-styles';

interface IFormSubmitDialogueProps {
  handleClose: () => void;
}

export const FormSubmitDialogue: React.FC<IFormSubmitDialogueProps> = ({
  handleClose,
}) => {
  return (
    <Dialog open={true} onClose={handleClose}>
      <Box
        sx={{
          height: 50,
          backgroundColor: lightColors.greenFaded,
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <CloseIcon
          onClick={handleClose}
          sx={{
            ...iconButtonStyles,
            color: lightColors.grey,
            cursor: 'pointer',
            mr: 1,
          }}
        />
      </Box>
      <DialogContent sx={{ textAlign: 'center' }}>
        <Typography variant='h4'>Success!</Typography>
        <Divider sx={{mt: 2, mb: 2}}/>
        <Typography>Your message has been sent.</Typography>
        <Typography>
          I will get back to you as soon as I am able, usually within 3 working
          days.
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
