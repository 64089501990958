import { Box } from '@mui/material';
interface IPageBannerProps {
  image: string;
  alt: string;
}
export const PageBanner: React.FC<IPageBannerProps> = ({ image, alt }) => {
  return (
    <Box sx={{ maxHeight: 500, overflow: 'hidden' }}>
      <Box
        component='img'
        sx={{
          width: '100%',
          mr: 'auto',
          ml: 'auto',
          backgroundImage: { image },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 0,
          zIndex: 1
        }}
        alt={alt}
        src={image}
      ></Box>
    </Box>
  );
};
