import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { RoutePath } from '../constants/routes';
import { HomePage } from './home';
import { AboutPage } from './about';
import { NotFoundPage } from './not-found';
import { FeesPage } from './fees';
import { ContactPage } from './contact';
import { PageLayout } from '../component-library/page-layout/page-layout';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RefugeePage } from './refugee';
import { FaqPage } from './faq';
import { SupervisionAndTrainingPage } from './supervision-and-training';

export const App: React.FC = () => {
  const theme = createTheme({
    typography: {
      fontFamily: 'Nunito',
    },
  });
  return (
    <div className='App'>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Routes>
            <Route path={RoutePath.HOME} element={<PageLayout />}>
              <Route index element={<HomePage />} />
              <Route path={RoutePath.ABOUT} element={<AboutPage />} />
              <Route path={RoutePath.UNKNOWN} element={<NotFoundPage />} />
              <Route path={RoutePath.FEES} element={<FeesPage />} />
              <Route path={RoutePath.CONTACT} element={<ContactPage />} />
              {/* <Route path={RoutePath.REFUGEE} element={<RefugeePage />} /> */}
              <Route path={RoutePath.FAQ} element={<FaqPage />} />
              <Route
                path={RoutePath.SUPERVISION_AND_TRAINING}
                element={<SupervisionAndTrainingPage />}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};
