export   const listOfConditions = [
  'Anxiety and related difficulties',
  'Panic and fear, as well as phobia related problems',
  'Family and relationship issues, including difficulties with intimacy',
  'Bereavement and grief',
  'Depression and mood difficulties',
  'Trauma related issues including complex trauma',
  'Identity questions including sexuality and gender',
  'Cultural and community pressures',
  'Concerns related to spirituality and faith practices',
  'Eating difficulties',
  'Addiction and substance misuse',
];