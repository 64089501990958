import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { lightColors } from '../../../constants/colors';

interface IFormButtonProps {
  loading: boolean;
}
export const FormButton: React.FC<IFormButtonProps> = ({ loading }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Button
        type='submit'
        variant='contained'
        sx={{
          width: { xs: '100%', md: '70%' },
          backgroundColor: lightColors.green,
          ml: 'auto',
          mr: 'auto',
          p: { sm: 2 },
          fontSize: { sm: 20, lg: 25 },
        }}
      >
        {loading ? (
          <CircularProgress
            size={20}
            thickness={5}
            sx={{ color: lightColors.white }}
          />
        ) : (
          'Send'
        )}
      </Button>
    </Box>
  );
};
